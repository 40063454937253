@import "https://fonts.googleapis.com/css2?family=Pacifico&family=Raleway:wght@200;400&display=swap";
*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
  padding-right: 0;
}

@media (width >= 600px) {
  .container {
    max-width: 600px;
  }
}

@media (width >= 728px) {
  .container {
    max-width: 728px;
  }
}

@media (width >= 984px) {
  .container {
    max-width: 984px;
  }
}

@media (width >= 1100px) {
  .container {
    max-width: 1100px;
  }
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.-mt-12 {
  margin-top: -3rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-32 {
  margin-bottom: 8rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-8 {
  margin-top: 2rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.aspect-video {
  aspect-ratio: 16 / 9;
}

.max-h-\[140px\] {
  max-height: 140px;
}

.w-full {
  width: 100%;
}

.rounded {
  border-radius: .25rem;
}

.rounded-md {
  border-radius: .375rem;
}

.border-2 {
  border-width: 2px;
}

.border-solid {
  border-style: solid;
}

.border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity));
}

.bg-dark {
  --tw-bg-opacity: 1;
  background-color: rgb(60 54 63 / var(--tw-bg-opacity));
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.bg-red-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 202 202 / var(--tw-bg-opacity));
}

.bg-sky-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(186 230 253 / var(--tw-bg-opacity));
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.text-center {
  text-align: center;
}

.font-raleway {
  font-family: Raleway, sans-serif;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.font-bold {
  font-weight: 700;
}

.text-blue-500 {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity));
}

.text-dark {
  --tw-text-opacity: 1;
  color: rgb(60 54 63 / var(--tw-text-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}

.text-primary {
  --tw-text-opacity: 1;
  color: rgb(211 53 49 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

html {
  scroll-behavior: smooth;
}

.small__menu {
  display: flex;
  position: absolute;
}

.small__menu__item {
  --tw-bg-opacity: 1;
  background-color: rgb(211 53 49 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  border-radius: .375rem;
  margin-top: .5rem;
  margin-left: .5rem;
  padding: .25rem .5rem;
  font-family: Raleway, sans-serif;
}

.small__menu__item:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(234 90 82 / var(--tw-bg-opacity));
}

.content__hero {
  background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
  --tw-gradient-from: #d4d4d4 var(--tw-gradient-from-position);
  --tw-gradient-to: #fafafa var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.content__hero__brand {
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.content__hero__brand__logo {
  width: 6rem;
  height: 6rem;
  margin-top: 3rem;
}

@media (width >= 768px) {
  .content__hero__brand__logo {
    width: 8rem;
    height: 8rem;
  }
}

.content__hero__brand__title {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(60 54 63 / var(--tw-text-opacity));
  margin-top: .25rem;
  font-family: Pacifico, sans-serif;
  font-size: 2.25rem;
  line-height: 2.5rem;
}

@media (width >= 768px) {
  .content__hero__brand__title {
    font-size: 3rem;
    line-height: 1;
  }
}

.content__hero__brand__subtitle {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(60 54 63 / var(--tw-text-opacity));
  max-width: 48rem;
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  font-family: Raleway, sans-serif;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.75rem;
}

.content__hero__container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
  padding-right: 0;
}

@media (width >= 600px) {
  .content__hero__container {
    max-width: 600px;
  }
}

@media (width >= 728px) {
  .content__hero__container {
    max-width: 728px;
  }
}

@media (width >= 984px) {
  .content__hero__container {
    max-width: 984px;
  }
}

@media (width >= 1100px) {
  .content__hero__container {
    max-width: 1100px;
  }
}

.content__hero__container {
  padding-bottom: 2.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.content__hero__container__screenshot {
  --tw-border-opacity: 1;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(156 163 175 / var(--tw-border-opacity));
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .25rem;
  width: 100%;
  margin-top: 1.5rem;
  margin-left: auto;
  margin-right: auto;
}

.content__hero__container__screenshot:hover {
  cursor: pointer;
  opacity: .9;
}

.form {
  text-align: center;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem;
  display: block;
}

.form__title {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(211 53 49 / var(--tw-text-opacity));
  font-family: Raleway, sans-serif;
  font-size: 1.875rem;
  font-weight: 700;
  line-height: 2.25rem;
  display: block;
}

@media (width >= 768px) {
  .form__title {
    font-size: 3rem;
    line-height: 1;
  }
}

.form__subtitle {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(211 53 49 / var(--tw-text-opacity));
  margin-top: 1rem;
  font-family: Raleway, sans-serif;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.75rem;
}

@media (width >= 1024px) {
  .form__subtitle {
    font-size: 3rem;
    line-height: 1;
  }
}

.form__desc {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(60 54 63 / var(--tw-text-opacity));
  margin-top: 1rem;
  font-family: Raleway, sans-serif;
  font-weight: 700;
}

@media (width >= 1024px) {
  .form__desc {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

.form__chevron_down {
  width: 4rem;
  height: 4rem;
  margin-top: .5rem;
  margin-left: auto;
  margin-right: auto;
}

.form__promo {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(211 53 49 / var(--tw-text-opacity));
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-family: Raleway, sans-serif;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.75rem;
}

.form__promo__price {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(60 54 63 / var(--tw-text-opacity));
  margin-top: 2rem;
  font-family: Raleway, sans-serif;
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.form__promo__price__badge {
  --tw-bg-opacity: 1;
  background-color: rgb(211 53 49 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  border-radius: .25rem;
  padding: .25rem .5rem;
  font-weight: 700;
}

.form__input {
  --tw-border-opacity: 1;
  border-width: 4px;
  border-color: rgb(211 53 49 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(60 54 63 / var(--tw-text-opacity));
  border-radius: .5rem;
  width: 100%;
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem;
  font-family: Raleway, sans-serif;
  display: block;
}

.form__input::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(153 27 27 / var(--tw-placeholder-opacity));
}

.form__input {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.form__input:focus {
  --tw-border-opacity: 1;
  border-color: rgb(97 197 233 / var(--tw-border-opacity));
  outline-offset: 2px;
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(97 197 233 / var(--tw-ring-opacity));
  outline: 2px solid #0000;
}

@media (width >= 640px) {
  .form__input {
    width: 20rem;
  }
}

@media (width >= 768px) {
  .form__input {
    width: 24rem;
  }
}

.form__submit {
  --tw-bg-opacity: 1;
  background-color: rgb(211 53 49 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .5rem;
  width: 100%;
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
  padding: 1.5rem 2rem;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.75rem;
  display: block;
}

.form__submit:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(97 197 233 / var(--tw-bg-opacity));
}

.form__submit:focus {
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(252 165 165 / var(--tw-ring-opacity));
  outline: 2px solid #0000;
}

@media (width >= 640px) {
  .form__submit {
    width: 15rem;
  }
}

.content__title {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(60 54 63 / var(--tw-text-opacity));
  font-family: Raleway, sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;
}

@media (width >= 768px) {
  .content__title {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
}

.content__title__light {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  margin-top: 2rem;
  font-family: Raleway, sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;
}

@media (width >= 768px) {
  .content__title__light {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
}

.content__paragraph {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(60 54 63 / var(--tw-text-opacity));
  margin-top: 1rem;
  font-family: Raleway, sans-serif;
  font-size: 1.125rem;
  line-height: 1.75rem;
}

@media (width >= 768px) {
  .content__paragraph {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

.content__paragraph__light {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  margin-top: .5rem;
  font-family: Raleway, sans-serif;
  font-size: 1.125rem;
  line-height: 1.75rem;
}

@media (width >= 768px) {
  .content__paragraph__light {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

.screenshot {
  --tw-border-opacity: 1;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(156 163 175 / var(--tw-border-opacity));
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .25rem;
  width: 100%;
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
}

.screenshot-sm {
  --tw-border-opacity: 1;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(156 163 175 / var(--tw-border-opacity));
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .375rem;
  margin-left: auto;
  margin-right: auto;
}

.separator {
  --tw-bg-opacity: 1;
  background-color: rgb(211 53 49 / var(--tw-bg-opacity));
  border-style: dotted;
  border-width: 0;
  width: 75%;
  height: .25rem;
  margin-top: 3.5rem;
  margin-left: auto;
  margin-right: auto;
}

.content__light {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
  padding-right: 0;
}

@media (width >= 600px) {
  .content__light {
    max-width: 600px;
  }
}

@media (width >= 728px) {
  .content__light {
    max-width: 728px;
  }
}

@media (width >= 984px) {
  .content__light {
    max-width: 984px;
  }
}

@media (width >= 1100px) {
  .content__light {
    max-width: 1100px;
  }
}

.content__light {
  padding: 3rem 1.5rem;
}

.content__services__grid {
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 1rem;
  margin-top: .5rem;
  padding: 1rem;
  display: grid;
}

@media (width >= 768px) {
  .content__services__grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.content__services__grid__item {
  --tw-bg-opacity: 1;
  background-color: rgb(211 53 49 / var(--tw-bg-opacity));
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .25rem;
  padding: 1rem;
  font-family: Raleway, sans-serif;
  font-weight: 700;
}

.content__blog {
  background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
  --tw-gradient-from: #61c5e9 var(--tw-gradient-from-position);
  --tw-gradient-to: #95d4ec var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  padding: 6rem 1.5rem;
}

.content__blog__title {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  margin-bottom: 1rem;
  font-family: Pacifico, sans-serif;
  font-size: 1.875rem;
  line-height: 2.25rem;
}

@media (width >= 768px) {
  .content__blog__title {
    font-size: 3rem;
    line-height: 1;
  }
}

.content__blog__text {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  margin-top: 2rem;
  font-family: Raleway, sans-serif;
  font-size: 1.125rem;
  line-height: 1.75rem;
}

@media (width >= 768px) {
  .content__blog__text {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

.content__blog__button {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(97 197 233 / var(--tw-text-opacity));
  border-radius: .5rem;
  width: 18rem;
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  padding: .625rem 1.25rem;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.75rem;
  display: block;
}

.content__blog__button:hover {
  cursor: pointer;
  --tw-bg-opacity: 1;
  background-color: rgb(240 249 255 / var(--tw-bg-opacity));
}

.content__blog__button:focus {
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(125 211 252 / var(--tw-ring-opacity));
  outline: 2px solid #0000;
}

.content__facebook {
  background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
  --tw-gradient-from: #4267b2 var(--tw-gradient-from-position);
  --tw-gradient-to: #0ea5ea var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  padding: 6rem 1.5rem;
}

.content__facebook__title {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  margin-bottom: 1rem;
  font-family: Pacifico, sans-serif;
  font-size: 1.875rem;
  line-height: 2.25rem;
}

@media (width >= 768px) {
  .content__facebook__title {
    font-size: 3rem;
    line-height: 1;
  }
}

.content__facebook__text {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  margin-top: 2rem;
  font-family: Raleway, sans-serif;
  font-size: 1.125rem;
  line-height: 1.75rem;
}

@media (width >= 768px) {
  .content__facebook__text {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

.content__facebook__button {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(66 103 178 / var(--tw-text-opacity));
  border-radius: .5rem;
  width: 18rem;
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  padding: .625rem 1.25rem;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.75rem;
  display: block;
}

.content__facebook__button:hover {
  cursor: pointer;
  --tw-bg-opacity: 1;
  background-color: rgb(240 249 255 / var(--tw-bg-opacity));
}

.content__facebook__button:focus {
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(125 211 252 / var(--tw-ring-opacity));
  outline: 2px solid #0000;
}

.content__partners {
  background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
  --tw-gradient-from: #61c5e9 var(--tw-gradient-from-position);
  --tw-gradient-to: #95d4ec var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  padding: 6rem 1.5rem;
}

.content__partners__title {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(60 54 63 / var(--tw-text-opacity));
  margin-bottom: 1rem;
  font-family: Pacifico, sans-serif;
  font-size: 1.875rem;
  line-height: 2.25rem;
}

@media (width >= 768px) {
  .content__partners__title {
    font-size: 3rem;
    line-height: 1;
  }
}

.content__partners__text {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(60 54 63 / var(--tw-text-opacity));
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-family: Raleway, sans-serif;
  font-size: 1.125rem;
  line-height: 1.75rem;
}

@media (width >= 768px) {
  .content__partners__text {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

.content__partner__logos {
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 1rem;
  margin-top: 1rem;
  display: grid;
}

@media (width >= 768px) {
  .content__partner__logos {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (width >= 1280px) {
  .content__partner__logos {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    max-width: 65%;
  }
}

.content__partner__logo__container {
  --tw-bg-opacity: 1;
  background-color: rgb(250 250 250 / var(--tw-bg-opacity));
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .375rem;
  justify-content: center;
  align-items: center;
  height: 5rem;
  display: flex;
}

.content__partner__logo {
  --tw-grayscale: grayscale(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  max-height: 30px;
  margin-left: auto;
  margin-right: auto;
}

.content__partner__logo:hover {
  opacity: .8;
}

@media (width >= 1280px) {
  .content__partner__logo {
    max-height: 40px;
  }
}

.content__colorful {
  background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
  --tw-gradient-from: #d33531 var(--tw-gradient-from-position);
  --tw-gradient-to: #ea5a52 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  padding-bottom: 4rem;
}

.content__colorful_wrapper {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
  padding-right: 0;
}

@media (width >= 600px) {
  .content__colorful_wrapper {
    max-width: 600px;
  }
}

@media (width >= 728px) {
  .content__colorful_wrapper {
    max-width: 728px;
  }
}

@media (width >= 984px) {
  .content__colorful_wrapper {
    max-width: 984px;
  }
}

@media (width >= 1100px) {
  .content__colorful_wrapper {
    max-width: 1100px;
  }
}

.content__colorful_wrapper {
  padding-top: 1.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.content__colorful__palette {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .375rem;
  margin-top: 1.5rem;
  padding: .5rem;
  display: block;
}

.content__colorful__grid {
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: .25rem;
  margin-top: 1.5rem;
  margin-left: auto;
  margin-right: auto;
  display: grid;
}

.inset__shadow__hero {
  box-shadow: inset 0 -16px 16px -16px #0000004d;
}

.inset__shadow {
  box-shadow: inset 0 16px 16px -16px #0000004d, inset 0 -16px 16px -16px #0000004d;
}

.content__gray {
  background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
  --tw-gradient-from: #d4d4d4 var(--tw-gradient-from-position);
  --tw-gradient-to: #fafafa var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.content__paragraph__faq {
  --tw-text-opacity: 1;
  color: rgb(60 54 63 / var(--tw-text-opacity));
  margin-top: 1.5rem;
  font-family: Raleway, sans-serif;
  font-size: 1.125rem;
  line-height: 1.75rem;
}

@media (width >= 768px) {
  .content__paragraph__faq {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

.content__paragraph__faq__q {
  --tw-text-opacity: 1;
  color: rgb(211 53 49 / var(--tw-text-opacity));
  font-family: Raleway, sans-serif;
  font-weight: 700;
  display: block;
}

.content__paragraph__faq__a {
  --tw-text-opacity: 1;
  color: rgb(60 54 63 / var(--tw-text-opacity));
  margin-top: .5rem;
  font-family: Raleway, sans-serif;
}

.content__pricing {
  gap: 2.5rem;
  margin-top: 1.5rem;
  display: grid;
}

@media (width >= 640px) {
  .content__pricing {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

@media (width >= 768px) {
  .content__pricing {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

@media (width >= 1024px) {
  .content__pricing {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.content__pricing__target {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  display: block;
}

.content__pricing__amount {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  padding-top: .75rem;
  font-family: Verdana, sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;
  display: block;
}

@media (width >= 768px) {
  .content__pricing__amount {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
}

.content__pricing__desc {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  margin-top: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-size: .875rem;
  line-height: 1.25rem;
  display: block;
}

.content__pricing__block {
  --tw-border-opacity: 1;
  border-color: rgb(211 53 49 / var(--tw-border-opacity));
  background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
  --tw-gradient-from: #d33531 var(--tw-gradient-from-position);
  --tw-gradient-to: #b91c1c var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .5rem;
  padding: 2rem 1rem;
}

.content__pricing__block__team {
  --tw-border-opacity: 1;
  border-color: rgb(211 53 49 / var(--tw-border-opacity));
  background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
  --tw-gradient-from: #61c5e9 var(--tw-gradient-from-position);
  --tw-gradient-to: #0369a1 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .5rem;
  padding: 2rem 1rem;
}

.content__pricing__block__title {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  margin-bottom: 1rem;
  font-family: Raleway, sans-serif;
  font-size: 1.875rem;
  font-weight: 700;
  line-height: 2.25rem;
}

@media (width >= 768px) {
  .content__pricing__block__title {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
}

.footer {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
  padding-right: 0;
}

@media (width >= 600px) {
  .footer {
    max-width: 600px;
  }
}

@media (width >= 728px) {
  .footer {
    max-width: 728px;
  }
}

@media (width >= 984px) {
  .footer {
    max-width: 984px;
  }
}

@media (width >= 1100px) {
  .footer {
    max-width: 1100px;
  }
}

.footer {
  justify-content: space-between;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-family: Raleway, sans-serif;
  display: flex;
}

.footer-link {
  --tw-text-opacity: 1;
  color: rgb(60 54 63 / var(--tw-text-opacity));
  font-family: Raleway, sans-serif;
  text-decoration-line: underline;
}

.footer-link:hover {
  --tw-text-opacity: 1;
  color: rgb(211 53 49 / var(--tw-text-opacity));
}

.floating {
  animation-name: floating;
  animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@keyframes floating {
  0% {
    transform: translate(0);
  }

  50% {
    transform: translate(0, 5px);
  }

  100% {
    transform: translate(0);
  }
}

.hover\:cursor-pointer:hover {
  cursor: pointer;
}

.hover\:text-primary:hover {
  --tw-text-opacity: 1;
  color: rgb(211 53 49 / var(--tw-text-opacity));
}

@media (width >= 768px) {
  .md\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
}

/*# sourceMappingURL=index.138a3ab4.css.map */
