@import url('https://fonts.googleapis.com/css2?family=Pacifico&family=Raleway:wght@200;400&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    scroll-behavior:smooth
}

.small__menu {
    @apply absolute flex;
}

.small__menu__item {
    @apply font-raleway mt-2 ml-2 bg-primary rounded-md text-white px-2 py-1 hover:bg-primary-light;
}

/* CONTENT HERO */

.content__hero {
    @apply bg-gradient-to-br from-neutral-300 to-neutral-50 px-6;
}

.content__hero__brand {
    @apply mx-auto flex justify-center;
}

.content__hero__brand__logo {
    @apply mt-12 h-24 w-24 md:h-32 md:w-32;
}

.content__hero__brand__title {
    @apply mt-1 text-center font-pacifico text-4xl text-dark md:text-5xl;
}

.content__hero__brand__subtitle {
    @apply mx-auto mt-8 max-w-3xl text-center font-raleway text-xl text-dark font-bold;
}

/* CONTENT HERO CONTAINER */

.content__hero__container {
    @apply container px-6 pb-10;
}

.content__hero__container__screenshot {
    @apply mx-auto mt-6 w-full rounded border border-solid border-gray-400 shadow-xl hover:opacity-90 hover:cursor-pointer;
}

.form {
    @apply block text-center max-w-[900px] mx-auto p-4;
}

.form__title {
    @apply block text-center font-raleway text-3xl font-bold text-primary md:text-5xl;
}

.form__subtitle {
    @apply mt-4 text-center font-raleway text-xl font-bold text-primary lg:text-5xl;
}

.form__desc {
    @apply mt-4 text-center font-raleway font-bold text-dark lg:text-xl;
}

.form__chevron_down {
    @apply mx-auto mt-2 w-16 h-16;
}

.form__promo {
    @apply mt-4 text-center font-raleway text-xl text-primary font-bold mb-4;
}

.form__promo__price {
    @apply mt-8 text-center font-raleway text-dark text-xl;
}

.form__promo__price__badge {
    @apply font-bold text-white bg-primary py-1 px-2 rounded;
}

.form__input {
    @apply mx-auto shadow mt-4 block bg-white w-full sm:w-80 rounded-lg border-4 placeholder-red-800 border-primary p-4 font-raleway text-dark focus:border-secondary focus:ring-secondary focus:outline-none md:w-96;
}

.form__submit {
    @apply shadow mx-auto mt-4 block w-full sm:w-60 rounded-lg bg-primary px-8 py-6 text-xl font-medium text-white hover:bg-secondary focus:outline-none focus:ring-4 focus:ring-red-300;
}

/* CONTENT SHARED */

.content__title {
    @apply text-center font-raleway text-2xl md:text-3xl font-bold text-dark;
}

.content__title__light {
    @apply mt-8 text-center font-raleway text-2xl md:text-3xl font-bold text-white;
}

.content__paragraph {
    @apply mt-4 text-center font-raleway text-dark text-lg md:text-xl;
}

.content__paragraph__light {
    @apply mt-2 text-white text-center font-raleway text-lg md:text-xl;
}

.screenshot {
    @apply mx-auto mt-8 w-full border border-solid border-gray-400 rounded shadow-xl;
}

.screenshot-sm {
    @apply mx-auto border border-solid border-gray-400 shadow-xl rounded-md;
}

.separator {
    @apply h-1 mt-14 bg-primary border-0 border-dotted w-[75%] mx-auto;
}

/* CONTENT LIGHT CONTAINER */

.content__light {
    @apply container px-6 py-12;
}

.content__services__grid {
    @apply mt-2 grid grid-cols-1 gap-4 p-4 md:grid-cols-3;
}

.content__services__grid__item {
    @apply bg-primary text-white  rounded p-4 font-raleway font-bold text-center shadow-md;
}

/* CONTENT BLOG */

.content__blog {
    @apply bg-gradient-to-br from-secondary to-secondary-light px-6 py-24;
}

.content__blog__title {
    @apply mb-4 text-center font-pacifico text-3xl text-white md:text-5xl;
}

.content__blog__text {
    @apply mt-8 text-center font-raleway text-lg md:text-xl text-white;
}

.content__blog__button {
    @apply mx-auto mt-8 block w-72 rounded-lg bg-white px-5 py-2.5 text-center text-lg font-medium text-secondary hover:cursor-pointer hover:bg-sky-50 focus:outline-none focus:ring-4 focus:ring-sky-300;
}

/* CONTENT FACEBOOK */

.content__facebook {
    @apply bg-gradient-to-br from-[#4267B2] to-[#0ea5ea] px-6 py-24;
}

.content__facebook__title {
    @apply mb-4 text-center font-pacifico text-3xl text-white md:text-5xl;
}

.content__facebook__text {
    @apply mt-8 text-center font-raleway text-lg md:text-xl text-white;
}

.content__facebook__button {
    @apply mx-auto mt-8 block w-72 rounded-lg bg-white px-5 py-2.5 text-center text-lg font-medium text-[#4267B2] hover:cursor-pointer hover:bg-sky-50 focus:outline-none focus:ring-4 focus:ring-sky-300;
}

/* CONTENT PARTNERS */

.content__partners {
    @apply bg-gradient-to-br from-secondary to-secondary-light px-6 py-24;
}

.content__partners__title {
    @apply mb-4 text-center font-pacifico text-3xl text-dark md:text-5xl;
}

.content__partners__text {
    @apply mt-8 text-center font-raleway text-lg md:text-xl text-dark mb-8;
}

.content__partner__logos {
    @apply mt-4 grid-cols-1 grid gap-4 xl:grid-cols-3 md:mx-auto  xl:max-w-[65%];
}

.content__partner__logo__container {
    @apply bg-neutral-50 rounded-md h-20 flex items-center justify-center shadow-md;
}

.content__partner__logo {
    @apply max-h-[30px] mx-auto hover:opacity-80 xl:max-h-[40px] grayscale;
}

/* CONTENT COLORFUL */

.content__colorful {
    /*@apply bg-gradient-to-br from-sky-400 via-rose-400 to-lime-400 pb-16;*/
    @apply bg-gradient-to-br from-primary to-primary-light pb-16;
}

.content__colorful_wrapper {
    @apply container px-6 pt-6;
}

.content__colorful__palette {
    @apply mt-6 block bg-white p-2 rounded-md shadow-md;
}

.content__colorful__grid {
    @apply mx-auto mt-6 grid grid-cols-3 gap-1;
}

/* CONTENT GRAY */

.inset__shadow__hero {
    box-shadow:
            inset 0 -16px 16px -16px rgba(0, 0, 0, 0.3);
}

.inset__shadow {
    box-shadow:
            inset 0 16px 16px -16px rgba(0, 0, 0, 0.3),
            inset 0 -16px 16px -16px rgba(0, 0, 0, 0.3);
}

.content__gray {
    @apply py-12 bg-gradient-to-br from-neutral-300 to-neutral-50;
}

/* CONTENT FAQ */

.content__paragraph__faq {
    @apply mt-6 font-raleway text-lg md:text-xl text-dark;
}

.content__paragraph__faq__q {
    @apply font-bold font-raleway text-primary block;
}

.content__paragraph__faq__a {
    @apply mt-2 font-raleway text-dark;
}

/* PRICING */

.content__pricing {
    @apply grid mt-6 gap-10 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2;
}

.content__pricing__target {
    @apply block text-white;
}

.content__pricing__amount {
    @apply block pt-3 text-white font-verdana text-2xl md:text-4xl font-bold;
}

.content__pricing__desc {
    @apply mt-4 block text-white text-sm px-6;
}

.content__pricing__block {
    @apply border-primary px-4 py-8 shadow-md rounded-lg text-white bg-gradient-to-br from-primary to-red-700;
}

.content__pricing__block__team {
    @apply border-primary px-4 py-8 shadow-md rounded-lg text-white bg-gradient-to-br from-secondary to-sky-700;
}

.content__pricing__block__title {
    @apply text-center text-white text-3xl md:text-4xl font-raleway mb-4 font-bold;
}

/* FOOTER */

.footer {
    @apply container flex justify-between px-6 font-raleway;
}

.footer-link {
    @apply text-dark hover:text-primary underline font-raleway;
}

.floating {
    animation-name: floating;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

@keyframes floating {
    0% { transform: translate(0,  0px); }
    50%  { transform: translate(0, 5px); }
    100%   { transform: translate(0, -0px); }
}